<template>
  <main id="auth-code" class="next-container">
    <section-base>
      <div class="heading-col">
        <h1 class="color-primary-100 heading-mr">
          Willkommen <br />zur Zählerstandserfassung
        </h1>

        <p class=" text-subtitle-mr color-accent-300">
          Für eine korrekte Berechnung zur Nutzung Ihres Strompaketes benötigen
          wir in regelmäßigen Abständen aktuelle Zählerstände.
        </p>
      </div>

      <div class="heading-col sub-description-mr">
        <h3 class="color-primary-100">
          Bitte geben Sie zunächst den Code ein, denn Sie per E-Mail von uns
          erhalten haben.
        </h3>

        <p class="color-accent-300">
          Kunden mit konventionellen Messeinrichtungen können uns danach schnell
          und einfach den aktuellen Zählerstand melden. <br />
          Für Kunden mit Smartmeter entfällt die manuelle Erfassung. Sie werden
          direkt zum Dashboard mit der Auswertung der Verbräuche weitergeleitet.
        </p>
      </div>

      <form
        @submit.prevent="passToMeterReading"
        class="meter-reading-code-form"
      >
        <div class="form-grid form-grid-col">
          <div class="form-grid-item auth-mr">
            <input-regular
              v-model="fieldsMR.tokenMR.value"
              :validation="fieldsMR.tokenMR"
              type="text"
              :showLabel="true"
              :label="'Ihr E-Mail Code'"
            />

            <div class="button-block">
              <button-regular :type="'submit'" :class="'button-accent'">
                Weiter zur Zählerstandserfassung
              </button-regular>
            </div>
          </div>
        </div>
      </form>
    </section-base>
  </main>
</template>

<script>
import { ctaAuthCode } from '@/placeholder/cta';
import { fieldsMR } from '@/placeholder/fieldsValidation';
import InputRegular from '@/components/next/input/input-regular.vue';
import ButtonRegular from '@/components/next/button-regular.vue';
import SectionBase from '@/components/next/section-base.vue';

export default {
  components: { InputRegular, ButtonRegular, SectionBase },

  data() {
    return {
      ctaAuthCode,
      fieldsMR
    };
  },

  methods: {
    passToMeterReading() {
      if (this.fieldsMR.tokenMR.value === '') {
        this.fieldsMR.tokenMR.valid = false;
        this.fieldsMR.tokenMR.error = 'Dieses Feld muss angegeben werden';
      } else {
        this.fieldsMR.tokenMR.valid = true;
        this.$store.commit('user/SET_CURRENT_FLOW', 'mr_dashboard');
        this.$store.dispatch('user/GET_METER_READING_DATA', {
          uuid: this.fieldsMR.tokenMR.value,
          push: true
        });
      }
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
