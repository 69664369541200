module.exports.ctaFormPage = [
    'sicheren Preisen für die gekaufte Menge - Sie kaufen Ihre Menge zum Festpreis',
    'flexibler Nutzung - ohne Vertragslaufzeit',
    '0 € monatlicher Grundgebühr',
    'weiteren Rabatten bei Einmalzahlung Ihres Paketes'
]

module.exports.initialOfferCTA = [
    'Keine Vertragslaufzeit',
    'Günstige feststehende Preise',
    'Strompakete sind unbegrenzt gültig',
    '100% grüner Strom',
    'Onlineprodukt, komplett digitale Abwicklung'
]

module.exports.followingOfferCTA = [
    'sicheren Preisen für die gekaufte Menge\n' +
    '- Sie kaufen Ihre Menge zum Festpreis',
    'flexibler Nutzung - ohne Vertragslaufzeit',
    '0 € monatlicher Grundgebühr',
    'weiteren Rabatten bei Einmalzahlung Ihres Paketes',
]


module.exports.ctaAuthCode = [
    'Keine Vertragslaufzeit',
    'Günstige feststehende Preise',
    'Strompakete sind unbegrenzt gültig',
    '100% grüner Strom',
    'Onlineprodukt, komplett digitale Abwicklung'
]

module.exports.ctaPackages = [{
        id: 'A',
        recomend: true,
        title: 'Ihr neues Strompaket',
        image: 'https://cache.eprimo.de/var/site/storage/images/_aliases/width350/0/7/9/3/13970-1-ger-DE/eCommerce_Strom_12.png',
        features: [
            'Menge: 3.600 kWh',
            'Reicht etwa: 12 Monate',
            'Keine Mindestlaufzeit',
        ],
        prices: {
            month: {
                value: '90,83',
                title: '24 monatliche Raten',
                brief: 'Gesamtpreis: 1.090,00 €',
                small: 'Reicht etwa: 12 Monate'
            },
            year: {
                value: '1090,00',
                title: 'Einmalzahlung',
                brief: 'Einmalpreis für Paket',
                small: '(bereits rabbattiert, 5% Skonto)',
                sale: 5,
            }
        }
    },
    {
        id: 'B',
        recomend: false,
        title: 'Strompaket S',
        image: 'https://cache.eprimo.de/var/site/storage/images/_aliases/width350/0/1/8/3/13810-1-ger-DE/eCommerce_GSC.png',
        features: [
            'Menge: 5.200 kWh',
            'Reicht etwa: 18 Monate',
            'Keine Mindestlaufzeit',
        ],
        prices: {
            month: {
                value: '90,83',
                title: '24 monatliche Raten',
                brief: 'Gesamtpreis: 1.090,00 €',
                small: 'Reicht etwa: 12 Monate'
            },
            year: {
                value: '1090,00',
                title: 'Einmalzahlung',
                brief: 'Einmalpreis für Paket',
                small: '(bereits rabbattiert, 5% Skonto)',
                sale: 5,
            }
        }
    },
]

module.exports.ways = [{
    step: 1,
    title: 'Sie erhalten von uns eine Bestätigung für Ihr neues Strompaket und vervollständigen Ihre Registrierung bei mein eprimo',
    icon: 'formular'
},
{
    step: 2,
    title: 'Den bisherigen Verbrauch Ihres alten Vertrages rechnen wir ab und Sie erhalten dazu eine Endabrechnung von uns',
    icon: 'zahlung'
},
{
    step: 3,
    title: 'Ab dem nächsten Monat nutzen Sie Ihr eprimo Strompaket und profitieren von den neuen günstigen Konditionen',
    icon: 'stromverbrauch'
},
{
    step: 4,
    title: 'Regelmäßige Zählerstande helfen beim Überblick über Ihr Paket. Wir erinnern Sie gerne per E-Mail an die Übermittlung',
    icon: 'stromzaehler'
}, 

]

module.exports.smartmeterCTA = [
    {
        title: 'Ihre Vorteile im Überblick: ',
        strings:[
            'Die regelmäßige Ablesung Ihres Zählers und der Termin zur Ablesung \n durch den Stromableser entfällt',
            'Sie erhalten regelrnaßig eine Ubersicht über Ihren Stromverbrauch',
            'Sie nehmen aktiv an der Erneuerung unserer Stromnetze teil',
            'Sie erhalten in Zukunft Zugriff auf weitere smarte Services ',
        ]
    },
    {
        title: 'Jetzt als befristetes Vorteilsangebot für Sie komplett kostenlos: ',
        strings:[
            'kostenloser SmartMeter',
            'kostenloser Einbau des SmartMeters',
            'Entgelte für die Messung werden von eprimo übernommen',
            'Entgelte für den digitalen Messstellenbetrieb sind bereits\n in Ihrem Strompaket enthalten ',
        ]
    },
]